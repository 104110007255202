.container-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

.driver-photo {
    margin-top: 9px;
    width: 40px;
    height: 60px;
    margin-right: 7px;
    padding-right: 4px;
  }
 
.ongoing-mapcontainer {
  position: fixed;
  top: 42px;
  z-index: 0;
  right: 0;
  height: calc(100% - 42px);
  width: 100%;
}

.ongoing-mapelement {
  height: 100%;
}
  
.ongoing-empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ongoing-requestcard {
  width: 650px;
  max-width: 90%;
}

.ongoing-card {
  width: 550px;
  cursor: pointer;
}

.ongoing-cardheader {
  color: #99a5b3;
  font-weight: normal !important;
  font-size: 18px;
}

.ongoing-progressbar {
  height: 8px;
}

.ongoing-cardbody {
  font-size: 14px;
}

.ongoing-drivericoncircle {
  background-color: #f7f7f7;
  border: 2px solid #e2e2e2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ongoing-drivericon {
  color: #a2a2a2;
  margin-bottom: -10px;
}

.ongoing-subtotal {
  color: #6c757d;
  font-size: 14px;
}

.ongoing-circlecontainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.ongoing-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ongoing-yellow {
  background-color: orange;
}

.ongoing-green {
  background-color: green;
}

.ongoing-deliverymenu {
  color: rgba(0, 0, 0, .54);
  cursor: pointer;
}

.ongoing-cancelbtn {
  font-size: 14px;
}

.ongoing-cancelbtn:hover {
  background-color: transparent !important;
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  transition: all 0.3s ease-out;
  outline: #dc3545 !important;
  box-shadow: none !important;
}
