.customtable-container {
  border-radius: 8px;
  border: 1px solid #dfe2e6;
  overflow: hidden;
  font-size: 14px !important;
}

.customtable-table {
  margin-bottom: 0 !important;
}

.customtable-table thead th {
  background-color: #f5f6f7;
  color: #636a71 !important;
  font-weight: normal;
  border-top-width: 0;
  border-bottom-width: 0;
}

.customtable-table tbody td {
  background-color: #fff;
}

.customtable-table tr.clickable:hover {
  cursor: pointer;
}
.customtable-table tr.clickable:hover td {
  background-color: #f5f6f7;
}