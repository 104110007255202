.requestpage-fade {
  color: #636a71;
  font-size: 14px;
}

.requestpage-pricetotal {
  color: #000;
  font-weight: bold;
}

.requestpage-yellow {
  color: #ffb11f;
}

.requestpage-green {
  color: #0bce35;
}

.requestpage-tools {
  text-align: right;
}

.requestpage-invoicebtn:disabled {
  cursor: help;
  filter: grayscale(100%);
}

.requestpage-image {
  max-width: 100%;
}

.requestpage-orderdetails {
  padding-inline-start: 16px;
  border-inline-start: 1px solid rgba(0, 0, 0, .12);
}

.requestpage-signature {
  width: 125px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  outline: 1px dashed #dfe2e6;
  margin-top: -27px;
  float: right;
  color: #636a71;
}

.badge {
  color: #636a71;
  background-color: #f5f6f7;
  font-weight: 400;
}

.requestpage-pricinginfo{
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  max-height: 120px;
  margin: 3%;
  padding: 1%;
  overflow: auto;
}