@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
.pagespinner-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.usermenuitem-item {
  paddingLeft: 4px;
  paddingRight: 4px;
}
.usermenuitem-icon {
  margin: 0 4px;
  color: #99a5b3;
}
.usermenuitem-label {
  margin: 0 4px
}

.usermenu-toggle {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  cursor: pointer;
}
.usermenu-avatar {
  background-color: #e7f4fd;
  border: 2px solid #e2e2e2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: border .1s ease-in;
}
.usermenu-avatar:hover {
  border-color: #99a5b3;
}
.usermenu-icon {
  color: #245778;
  margin-bottom: -10px;
}

.header-navbar {
    border: 1px solid #dfe2e6 !important;
    padding: 0 16px !important;
}

.header-nav {
  font-size: 14px !important;
  height: 49px;
}

.header-mainlink {
  font-weight: bold !important;
  color: #1a5778 !important;
  height: 100% !important;
}

.header-nav .active {
  color: #1f2933 !important;
  font-weight: bold !important;
  border-bottom: 2px solid #245778 !important;
}

.header-navlink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamicinput-label {
  text-transform: capitalize;
}

.dynamicinput-invalidmsg {
  color: red;
}

.addresslistitem-item {
  display: grid;
  width: 100%;
  height: 52px;
  font-size: 14px;
  grid-template-areas: "icon name"
                       "icon address";
  grid-template-rows: auto auto;
  grid-template-columns: 52px auto;
  cursor: pointer;
  margin: 2px;
  padding: 2px;
  box-sizing: border-box;
  border-radius: 2px;
}

.addresslistitem-item:hover {
  background-color: #f0f3f7;
}

.addresslistitem-itemicon {
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addresslistitem-itemname {
  grid-area: name;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.addresslistitem-itemname::after{
  content: attr(data-content);
}
.addresslistitem-itemname[data-content=" "]::after{
  content: attr(data-alt);
}

.addresslistitem-itemaddress {
  grid-area: address;
}
.addressinput-group {
  position: relative;
}

.form-control.is-invalid, .was-validated .addressinput-field:invalid,
.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  background-image: none;
  border-color: #ced4da;
  outline: none;
  box-shadow: none;
}

.form-control.is-invalid:focus, .was-validated .addressinput-field:invalid:focus {
  border-color: #99a5b3;
}

.addressinput-predictions {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.05);
  border: 1px solid #ced4da;
  display: flex;
  flex-direction: column;
  padding: 2px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  width: 100%;
}
.address-iconcontainer {
  display: inline-block;
}

.address-iconcontainer[status="empty"] img {
  filter: grayscale(100%);
}

.address-iconcontainer[status="valid"] img {
  filter: none;
}

.address-iconcontainer[status="invalid"] {
  background-color: red;
}

.address-iconcontainer[status="invalid"] img {
  mix-blend-mode: darken;
}

.address-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.address-phone-flag {
  width: 20px;
}

.address-invalidmsg {
  color: red;
}

.ordervehicle-price {
  color:#0bce35;
}

.ordervehicle-row {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 2px 0;
}

.ordervehicle-row:not([disabled]):hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .03);
}

.ordervehicle-error {
  color: rgba(0, 0, 0, .38);
  min-height: 20px;
}

.ordervehicle-row[disabled] {
  opacity: .6;
}

.ordervehicle-form[disabled] {
  opacity: .6;
}

.orderoptions-price {
  color:#0bce35;
}

.orderoptions-row {
  padding: 4px;
  border-radius: 4px;
}

.orderoptions-row:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, .06);
}

.orderoptions-row[disabled] {
  opacity: .6;
}

.orderoptions-error {
  cursor: help;
}

.orderoptions-form[disabled] {
  opacity: .6;
}

.request-page {
  position: relative;
}

.request-formcontainer {
  width: 650px;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
}

.request-mapcontainer {
  position: fixed;
  top: 42px;
  z-index: 0;
  right: 0;
  height: calc(100% - 42px);
  width: calc(100% - 650px);
}

.request-mapelement {
  height: 100%;
}

.request-options[disabled] {
  opacity: 0.6;
}

.request-pricerow {
  border-top: 1px solid #dfe2e6 !important;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 650px;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

@media only screen and (max-width: 770px) {
  .request-formcontainer, .request-pricerow {
    width: 100%;
    box-shadow: none;
  }

  .request-mapcontainer {
    display: none;
  }
}

.requestcanceller-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
}

.requestcanceller-alert {
  color: red;
}

.requestcanceller-address {
  text-align: center;
}

.requestcanceller-body {
  background-color: #f5f6f7;
  color: #636a71;
  font-size: 14px;
  height: 300px;
  overflow-y: scroll;
}

.requestcanceller-label {
  cursor: pointer;
  height: 44px;
  text-transform: capitalize;
}

.requestcanceller-footer {
  display: flex;
  justify-content: flex-end;
  grid-gap: 8px;
  gap: 8px;
}

.requestcanceller-comment {
  border: 0;
  border-bottom: 1px solid #dfe2e6;
  color: #0a0b0c;
  transition: border-color .1s ease-in;
  width: 100%;
  outline: none;
  resize: none;
  background-color: transparent;
  overflow: hidden
}

.requestcanceller-comment:focus {
  border-color: #11a3eb;
}

.container-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

.driver-photo {
    margin-top: 9px;
    width: 40px;
    height: 60px;
    margin-right: 7px;
    padding-right: 4px;
  }
 
.ongoing-mapcontainer {
  position: fixed;
  top: 42px;
  z-index: 0;
  right: 0;
  height: calc(100% - 42px);
  width: 100%;
}

.ongoing-mapelement {
  height: 100%;
}
  
.ongoing-empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ongoing-requestcard {
  width: 650px;
  max-width: 90%;
}

.ongoing-card {
  width: 550px;
  cursor: pointer;
}

.ongoing-cardheader {
  color: #99a5b3;
  font-weight: normal !important;
  font-size: 18px;
}

.ongoing-progressbar {
  height: 8px;
}

.ongoing-cardbody {
  font-size: 14px;
}

.ongoing-drivericoncircle {
  background-color: #f7f7f7;
  border: 2px solid #e2e2e2;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ongoing-drivericon {
  color: #a2a2a2;
  margin-bottom: -10px;
}

.ongoing-subtotal {
  color: #6c757d;
  font-size: 14px;
}

.ongoing-circlecontainer {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}

.ongoing-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ongoing-yellow {
  background-color: orange;
}

.ongoing-green {
  background-color: green;
}

.ongoing-deliverymenu {
  color: rgba(0, 0, 0, .54);
  cursor: pointer;
}

.ongoing-cancelbtn {
  font-size: 14px;
}

.ongoing-cancelbtn:hover {
  background-color: transparent !important;
  color: #dc3545 !important;
  border-color: #dc3545 !important;
  transition: all 0.3s ease-out;
  outline: #dc3545 !important;
  box-shadow: none !important;
}

.customtable-container {
  border-radius: 8px;
  border: 1px solid #dfe2e6;
  overflow: hidden;
  font-size: 14px !important;
}

.customtable-table {
  margin-bottom: 0 !important;
}

.customtable-table thead th {
  background-color: #f5f6f7;
  color: #636a71 !important;
  font-weight: normal;
  border-top-width: 0;
  border-bottom-width: 0;
}

.customtable-table tbody td {
  background-color: #fff;
}

.customtable-table tr.clickable:hover {
  cursor: pointer;
}
.customtable-table tr.clickable:hover td {
  background-color: #f5f6f7;
}
.pagesubtitle-container {
  background-color: #fff;
  padding: 16px 0;
}

.pagesubtitle-back {
  text-align: right;
}

.pagesubtitle-back > .pagesubtitle-icon {
  cursor: pointer;
}
.scheduled-empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scheduled-cancelicon {
  cursor: pointer;
  opacity: .6;
}

.scheduled-cancelicon:hover {
  opacity: 1;
}

.customtable-table tr {
  border: 1px solid #dfe2e6;
}

.customtable-table tbody tr:first-child {
  border-top-left-radius: 16px;
}

.customtable-table tbody:not(:first-of-type)::before {
  content: '';
  border-left: none;
  display: block;
  height: 15px;
}

.customtable-table tbody .history-item[disabled] td {
  color: red !important;
}

.customtable-table tbody .history-item:hover td {
  cursor: pointer;
}

.history-empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.history-downloadicon {
  background-color: #797b7e;
  cursor: pointer;
  color: white;
  text-align: center;
  border-radius: 2px;
  width: 40px;
}

.history-downloadicon[disabled] {
  opacity: 0.6;
  cursor: help;
}

.history-clearicon {
  cursor: pointer;
}

.customtable-container {
  border-color: transparent !important;
}

.customtable-table tr {
  border: 1px solid #dfe2e6;
}

.customtable-table tbody tr:first-child {
  border-top-left-radius: 16px;
}

.customtable-table tbody:not(:first-of-type)::before {
  content: '';
  border-left: none;
  display: block;
  height: 15px;
}

.customtable-table tbody .history-item[disabled] td {
  color: red !important;
}

.customtable-table tbody .history-item:hover td {
  cursor: pointer;
}

.settings-contentcard {
  min-height: 500px;
}

.settings-item {
  font-size: 14px;
  color: #000;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}

.settings-item:hover {
  color: inherit;
  text-decoration: none;
}

.settings-item.active {
  color: #11a3eb;
  background-color: rgba(17,163,235,.1);
}
.requestpage-fade {
  color: #636a71;
  font-size: 14px;
}

.requestpage-pricetotal {
  color: #000;
  font-weight: bold;
}

.requestpage-yellow {
  color: #ffb11f;
}

.requestpage-green {
  color: #0bce35;
}

.requestpage-tools {
  text-align: right;
}

.requestpage-invoicebtn:disabled {
  cursor: help;
  filter: grayscale(100%);
}

.requestpage-image {
  max-width: 100%;
}

.requestpage-orderdetails {
  padding-inline-start: 16px;
  border-inline-start: 1px solid rgba(0, 0, 0, .12);
}

.requestpage-signature {
  width: 125px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  outline: 1px dashed #dfe2e6;
  margin-top: -27px;
  float: right;
  color: #636a71;
}

.badge {
  color: #636a71;
  background-color: #f5f6f7;
  font-weight: 400;
}

.requestpage-pricinginfo{
  border: 1px solid #dfe2e6;
  border-radius: 8px;
  max-height: 120px;
  margin: 3%;
  padding: 1%;
  overflow: auto;
}
.login-container {
  height: 100vh;
}

.login-main-row {
  height: 100%;
}

.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.login-logo {
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;
}

.login-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #e6e7e9;
}

.login-illustration {
  width: 80%;
}

.login-errormessage {
  color: #ff465c;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.page-content {
  margin-top: 49px;
}

.App-default-component {
    border: 1px solid #dfe2e6;
    border-radius: 8px;
    background-color: white;
    color: #636a71;
    padding: 32px;
}

body {
  margin: 0;
  background-color: #f0f3f7; 
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

