.settings-contentcard {
  min-height: 500px;
}

.settings-item {
  font-size: 14px;
  color: #000;
  background-color: transparent;
  border: none;
  border-radius: 4px;
}

.settings-item:hover {
  color: inherit;
  text-decoration: none;
}

.settings-item.active {
  color: #11a3eb;
  background-color: rgba(17,163,235,.1);
}