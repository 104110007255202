.login-container {
  height: 100vh;
}

.login-main-row {
  height: 100%;
}

.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
}

.login-logo {
  position: absolute;
  top: 0;
  height: auto;
  width: 100%;
}

.login-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #e6e7e9;
}

.login-illustration {
  width: 80%;
}

.login-errormessage {
  color: #ff465c;
}
