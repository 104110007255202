.request-page {
  position: relative;
}

.request-formcontainer {
  width: 650px;
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.25);
}

.request-mapcontainer {
  position: fixed;
  top: 42px;
  z-index: 0;
  right: 0;
  height: calc(100% - 42px);
  width: calc(100% - 650px);
}

.request-mapelement {
  height: 100%;
}

.request-options[disabled] {
  opacity: 0.6;
}

.request-pricerow {
  border-top: 1px solid #dfe2e6 !important;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 650px;
  display: flex;
  justify-content: flex-end;
  padding: 8px;
}

@media only screen and (max-width: 770px) {
  .request-formcontainer, .request-pricerow {
    width: 100%;
    box-shadow: none;
  }

  .request-mapcontainer {
    display: none;
  }
}
