.history-empty {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.history-downloadicon {
  background-color: #797b7e;
  cursor: pointer;
  color: white;
  text-align: center;
  border-radius: 2px;
  width: 40px;
}

.history-downloadicon[disabled] {
  opacity: 0.6;
  cursor: help;
}

.history-clearicon {
  cursor: pointer;
}

.customtable-container {
  border-color: transparent !important;
}

.customtable-table tr {
  border: 1px solid #dfe2e6;
}

.customtable-table tbody tr:first-child {
  border-top-left-radius: 16px;
}

.customtable-table tbody:not(:first-of-type)::before {
  content: '';
  border-left: none;
  display: block;
  height: 15px;
}

.customtable-table tbody .history-item[disabled] td {
  color: red !important;
}

.customtable-table tbody .history-item:hover td {
  cursor: pointer;
}
